import { createReducer } from '@reduxjs/toolkit'
import {
  updateFetching,
  updateAddress,
  updateMarsRiseBalance,
  updateEthBalance,
  updateNFTAllowance,
  updateLotteryAllowance,
  updateConnected,
  updateRewardAmount,
  updateStakedItems,
  updateUnstakedItems,
  updateGetApproved,
  updateNFT1Balance,
  updateNFT2Balance,
  updateTokenBalance,
  updateStakedItems2,
  updateUnstakedItems2,
  updateGetApproved2
} from './actions'

export interface UserState {
  fetching: boolean;
  connected: boolean;
  address: string;
  ethBalance: string;
  // marsriseBalance: string;
  // nftAllowance: string;
  // lotteryAllowance: string;
  rewardAmount: Number;
  stakedItems: Number[];
  unstakedItems: Number[];
  stakedItems2: Number[];
  unstakedItems2: Number[];
  isApproved: boolean;
  isApproved2: boolean;
  nftBalance1: number;
  nftBalance2: number;
  tokenBalance: Number;
}

export const initialState: UserState = {
  fetching: false,
  connected: false,
  address: "",
  ethBalance: "",
  // marsriseBalance: "",
  // nftAllowance: "0",
  // lotteryAllowance: "0",
  rewardAmount: 0,
  stakedItems: [],
  unstakedItems: [],
  stakedItems2: [],
  unstakedItems2: [],
  isApproved: false,
  isApproved2: false,
  nftBalance1: 0,
  nftBalance2: 0,
  tokenBalance: 0
}

export default createReducer(initialState, (builder) => {
  builder
  .addCase(updateFetching, (state, { payload: { fetching } }) => {
    state.fetching = fetching
  })
  .addCase(updateConnected, (state, { payload: { connected } }) => {
    state.connected = connected
  })
  .addCase(updateAddress, (state, { payload: { address } }) => {
    state.address = address
  })
  .addCase(updateEthBalance, (state, { payload: { ethBalance } }) => {
    state.ethBalance = ethBalance
  })
  .addCase(updateRewardAmount, (state, { payload: { rewardAmount } }) => {
    state.rewardAmount = rewardAmount
  })
  .addCase(updateStakedItems, (state, { payload: { stakedItems } }) => {
    state.stakedItems = stakedItems
  })
  .addCase(updateUnstakedItems, (state, { payload: { unstakedItems } }) => {
    state.unstakedItems = unstakedItems
  })
  .addCase(updateStakedItems2, (state, { payload: { stakedItems2 } }) => {
    state.stakedItems2 = stakedItems2
  })
  .addCase(updateUnstakedItems2, (state, { payload: { unstakedItems2 } }) => {
    state.unstakedItems2 = unstakedItems2
  })
  .addCase(updateGetApproved, (state, {payload: { isApproved } }) => {
    state.isApproved = isApproved
  })
  .addCase(updateGetApproved2, (state, {payload: { isApproved2 } }) => {
    state.isApproved2 = isApproved2
  })
  .addCase(updateNFT1Balance, (state, { payload: {nftBalance1} }) =>{
    state.nftBalance1 = nftBalance1
  })
  .addCase(updateNFT2Balance, (state, {payload: { nftBalance2 }}) => {
    state.nftBalance2 = nftBalance2
  })
  .addCase(updateTokenBalance, (state, { payload: {tokenBalance} }) =>{
    state.tokenBalance = tokenBalance
  })
})
import addresses from 'constants/contracts'
import { Address } from 'constants/types'
import { CHAIN_ID } from 'constants/config'

export const getAddress = (address: Address): string => {
  const chainId = CHAIN_ID
  return address[chainId]
}

export const getMarsRiseAddress = () => {
  return getAddress(addresses.marsrise)
}

export const getNFTAddress = () => {
  return getAddress(addresses.nft)
}

export const getStakingAddress = () => {
  return getAddress(addresses.staking)
}

export const getTokenAddress = () => {
  return getAddress(addresses.token)
}

export const getNFTAddress2 = () => {
  return getAddress(addresses.nftgen2)
}

export const getNFTAddress3 = () => {
  return getAddress(addresses.nftgen3)
}

export const getLotteryAddress = () => {
  return getAddress(addresses.lottery)
}
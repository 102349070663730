import { useEffect } from 'react'
import { useAppDispatch } from 'state'
import { updateEthBalance, updateRewardAmount, updateMarsRiseBalance, updateNFTAllowance, updateLotteryAllowance, updateStakedItems, updateUnstakedItems, updateGetApproved, updateNFT1Balance, updateTokenBalance, updateNFT2Balance, updateStakedItems2, updateUnstakedItems2, updateGetApproved2 } from './actions'
import { useFastFresh } from 'hooks/useRefresh'
import { callBalanceOf } from 'utils/calls/token'
import { callGetApproved, callNFTBalance, callRewardAmount, callStakedItmes, callUnstakedItmes } from 'utils/calls/nft'
import { useWeb3 } from 'utils/useWeb3'
import { getNFTAddress, getNFTAddress2 } from 'utils/addressHelper'

export const useEthBalance = (web3: any | undefined, address: string | undefined) => {
  const dispatch = useAppDispatch()
  const fastRefresh = useFastFresh()
  useEffect(() => {
    const getETHBalance = async () => {
      const result = await web3.eth.getBalance(address)
      dispatch(updateEthBalance({ ethBalance: result }))
    }
    if (web3 && address) {
      getETHBalance()
    }
  }, [web3, address, dispatch, fastRefresh])
}

export const useRewardAmount = (web3: any, address: string) => {
  const dispatch = useAppDispatch()
  const fastRefresh = useFastFresh()
  useEffect(() => {
    const getRewardAmount = async() => {
      const result = await callRewardAmount(web3, address)
      if (result) {
        dispatch(updateRewardAmount({rewardAmount: result as Number}))
      }
    }
    if (web3 && address) {
      getRewardAmount()
    }
  }, [web3, address, dispatch, fastRefresh])
}

export const useStakedItems = (web3: any, address: string) => {
  const dispatch = useAppDispatch()
  const fastRefresh = useFastFresh()
  const nftAddress = getNFTAddress()
  const nftAddress2 = getNFTAddress2()
  useEffect(() => {
    const getStakedItems = async() => {
      let result = await callStakedItmes(web3, nftAddress, address)
      if (result.success) {
        dispatch(updateStakedItems({stakedItems: result.result as Number[]}))
      }
      result = await callStakedItmes(web3, nftAddress2, address)
      if (result.success) {
        dispatch(updateStakedItems2({stakedItems2: result.result as Number[]}))
      }
    }
    if (web3 && address) {
      getStakedItems()
    }
  }, [web3, address, dispatch, fastRefresh])
}

export const useUnstakedItems = (web3: any, address: string) => {
  const dispatch = useAppDispatch()
  const fastRefresh = useFastFresh()
  const nftAddress = getNFTAddress()
  const nftAddress2 = getNFTAddress2()
  useEffect(() => {
    const getUnstakedItems = async() => {
      let result = await callUnstakedItmes(web3, nftAddress, address)
      if (result.success) {
        dispatch(updateUnstakedItems({unstakedItems: result.result as Number[]}))
      }
      // result = await callUnstakedItmes(web3, nftAddress2, address)
      // if (result.success) {
      //   dispatch(updateUnstakedItems2({unstakedItems2: result.result as Number[]}))
      // }
    }
    if (web3 && address) {
      getUnstakedItems()
    }
  }, [web3, address, dispatch, fastRefresh])
}

export const useNFTBalance = (web3: any, address: string) => {
  const dispatch = useAppDispatch()
  const fastRefresh = useFastFresh()
  const nftAddress = getNFTAddress()
  const nftAddress2 = getNFTAddress2()
  useEffect(() => {
    const getNFTBalance = async() => {
      let result = await callNFTBalance(web3, nftAddress, address)
      if (result.success) {
        dispatch(updateNFT1Balance({nftBalance1: result.result}))
      }
      result = await callNFTBalance(web3, nftAddress2, address)
      if (result.success) {
        dispatch(updateNFT2Balance({nftBalance2: result.result}))
      }
    }
    if (web3 && address) {
      getNFTBalance()
    }
  }, [web3, address, dispatch, fastRefresh])
}

export const useApproved = (web3: any, address: string) => {
  const dispatch = useAppDispatch()
  const fastRefresh = useFastFresh()
  const nftAddress = getNFTAddress()
  const nftAddress2 = getNFTAddress2()
  useEffect(() => {
    const getIsApproved = async() => {
      let result = await callGetApproved(web3, nftAddress, address)
      if (result.success) {
        dispatch(updateGetApproved({isApproved: result.result as boolean}))
      }
      result = await callGetApproved(web3, nftAddress2, address)
      if (result.success) {
        dispatch(updateGetApproved2({isApproved2: result.result as boolean}))
      }
    }
    if (web3 && address) {
      getIsApproved()
    }
  }, [web3, address, dispatch, fastRefresh])
}

export const useTokenBalance = (web3: any, address: string) => {
  const dispatch = useAppDispatch()
  const fastRefresh = useFastFresh()
  useEffect(() => {
    const getTokenBalance = async() => {
      const result = await callBalanceOf(web3, address)
      // console.log("tokenBal", result)
      if (result.success) {
        dispatch(updateTokenBalance({tokenBalance: result.result}))
      }
    }
    if (web3 && address) {
      getTokenBalance()
    }
  }, [web3, address, dispatch, fastRefresh])
}

export const useFetchUserData = (address: string) => {
  const web3 = useWeb3()
  useEthBalance(web3, address)
  useRewardAmount(web3, address)
  useStakedItems(web3, address)
  useUnstakedItems(web3, address)
  useApproved(web3, address)
  useNFTBalance(web3, address)
  useTokenBalance(web3, address)
}

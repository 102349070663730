import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../index'
import { updateFetching } from './actions'

export function useNFTFetchingManager(): [boolean, (_fetching: boolean) => void] {
  const dispatch = useDispatch()
  const fetching = useSelector<AppState, AppState['nft']['fetching']>((state) => state.nft.fetching)

  const setFetching = useCallback((_fetching: boolean) => {
    dispatch(updateFetching({ fetching: _fetching }))
  }, [dispatch])

  return [fetching, setFetching]
}

export function useNFTData() {
  const nft = useSelector<AppState, AppState['nft']>((state) => state.nft)
  return [nft]
}

export function useNFTs() {
  const data = useSelector<AppState, AppState['nft']['data']>((state) => state.nft.data)
  return [data]
}
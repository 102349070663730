import { createReducer } from '@reduxjs/toolkit'

import {
  updateFetching,
  updateMintPrice,
  updateTotalSupply,
  updateMintPrice2,
  updateTotalSupply2,
  updateMintPrice3,
  updateTotalSupply3,
  updateData,
  updateBoostPrice,
  updateAddNFTData,
} from './actions'

export interface TokenData {
  id: number
  boostLevel: string
  nextBoostTime: string
}

export interface NFTState {
  fetching: boolean
  totalSupply: number
  totalSupply2: number
  totalSupply3: number
  mintPrice: string
  mintPrice2: string
  mintPrice3: string
  boostPrice: string
  balance: number
  data: TokenData[]
}

export const initialState: NFTState = {
  fetching: false,
  totalSupply: 0,
  totalSupply2: 0,
  totalSupply3: 0,
  mintPrice: "",
  mintPrice2: "",
  mintPrice3: "",
  boostPrice: "",
  balance: 0,
  data: [],
}

export default createReducer(initialState, (builder) => {
  builder
  .addCase(updateFetching, (state, { payload: { fetching } }) => {
    state.fetching = fetching
  })
  .addCase(updateMintPrice, (state, { payload: { mintPrice } }) => {
    state.mintPrice = mintPrice
  })
  .addCase(updateTotalSupply, (state, { payload: { totalSupply } }) => {
    state.totalSupply = totalSupply
  })
  .addCase(updateMintPrice2, (state, { payload: { mintPrice } }) => {
    state.mintPrice2 = mintPrice
  })
  .addCase(updateTotalSupply2, (state, { payload: { totalSupply } }) => {
    state.totalSupply2 = totalSupply
  })
  .addCase(updateMintPrice3, (state, { payload: { mintPrice } }) => {
    state.mintPrice3 = mintPrice
  })
  .addCase(updateTotalSupply3, (state, { payload: { totalSupply } }) => {
    state.totalSupply3 = totalSupply
  })
  .addCase(updateBoostPrice, (state, { payload: { boostPrice } }) => {
    state.boostPrice = boostPrice
  })
  .addCase(updateData, (state, { payload: { data } }) => {
    state.data = data
  })
  .addCase(updateAddNFTData, (state, { payload: { data } }) => {
    let isInclude = false
    state.data.map(dt => {
      if(dt.id === data.id) {
        isInclude = true        
      }
      return true
    })
    if(!isInclude) {
      const nftArray = state.data.concat(data) 
      state.data = nftArray.sort((item1, item2) => item1.id - item2.id)
    }
  })
  
})
import BigNumber from 'bignumber.js'
import { parseEther } from '@ethersproject/units'
import { BIG_TEN } from 'utils/bigNumber'
import { add, convertStringToNumber } from 'helpers/bignumber'
import { getNFT2Contract, getNFT1Contract, getStakingContract } from '../contractHelper'
import { getNFTAddress, getNFTAddress2, getStakingAddress } from 'utils/addressHelper'

export function callRewardAmount(web3:any, address: string) {
  const stakingContract = getStakingContract(web3)
  return new Promise(async(resolve, reject) => {
    await stakingContract.methods
      .pendingTotalReward(address)
      .call((err: any, data: any) => {
        if (err) {
          reject(err)
        }
        resolve(data)
      })
  })
}

export const callGetApproved = async(web3: any, collection: string, address: string) => {
  const stakingAddress = getStakingAddress()
  let nftContract: any;
  if (collection == getNFTAddress()) {
    nftContract = getNFT1Contract(web3)
  } else if (collection == getNFTAddress2()) {
    nftContract = getNFT2Contract(web3)
  }
  try {
    let res = await nftContract.methods.isApprovedForAll(address, stakingAddress).call()
    return {
      success: true,
      result: res
    }
  } catch (error) {
    return {
      success: false
    }
  }
}

export const callStakedItmes = async(web3:any, collection: string, address: string) => {
  const stakingContract = getStakingContract(web3)
  try {
    let res = await stakingContract.methods.getStakedItems(collection, address).call()
    return {
      success: true,
      result: res
    }
  } catch (error) {
    return {
      success: false
    }
  }
}

export const callUnstakedItmes = async(web3:any, collection: string, address: string) => {
  const stakingContract = getStakingContract(web3)
  try {
    let res = await stakingContract.methods.getUnstakedItems(collection, address).call()
    return {
      success: true,
      result: res
    }
  } catch (error) {
    return {
      success: false
    }
  }
}

// export function callUnstakedItmes(web3:any, address: string) {
//   const stakingContract = getStakingContract(web3)
//   return new Promise(async(resolve, reject) => {
//     await stakingContract.methods
//       .getUnstakedItems(address)
//       .call((err: any, data: any) => {
//         if (err) {
//           reject(err)
//         }
//         resolve(data)
//       })
//   })
// }

export const callNFTBalance = async(web3: any, collection: string, address: string) => {
  let nftContract: any;
  if (collection == getNFTAddress()) {
    nftContract = getNFT1Contract(web3)
  } else if (collection == getNFTAddress2()) {
    nftContract = getNFT2Contract(web3)
  }
  try {
    let res = await nftContract.methods.balanceOf(address).call()
    return {
      success: true,
      result: res
    }
  } catch (error) {
    return {
      success: false
    }
  }
}

export const callMintPrice = async(web3: any, address: string) => {
  const nftContract = getNFT1Contract(web3)
  try {
    const isVerified = await nftContract.methods.verifyUserForWhiteListCollection(address).call()
    console.log("Beast verify", isVerified)
    let val;
    if (isVerified) {
      val = await nftContract.methods.priceForWL().call()
    } else {
      val = await nftContract.methods.priceForPublicSale().call()
    }
    return {
      success: true,
      result: val
    }
  } catch (error) {
    return {
      success: false
    }
  }
  // return new Promise(async(resolve, reject) => {
  //   await nftContract.methods
  //     .priceForWL()
  //     .call((err: any, data: any) => {
  //       if (err) {
  //         reject(err)
  //       }
  //       resolve(data)
  //     })
  // })
}

export function callTotalSupply(web3: any) {
  const nftContract = getNFT1Contract(web3)
  return new Promise(async(resolve, reject) => {
    await nftContract.methods
      .totalSupply()
      .call((err: any, data: any) => {
        if (err) {
          reject(err)
        }
        resolve(data)
      })
  })
}

export function callTokenOfOwnerByIndex(web3: any, address: string, index: number) {
  const nftContract = getNFT1Contract(web3)
  return new Promise(async(resolve, reject) => {
    await nftContract.methods
      .tokenOfOwnerByIndex(address, index.toString())
      .call((err: any, data: any) => {
        if (err) {
          reject(err)
        }
        resolve(data)
      })
  })
}

export function callSpaceManInfoList(web3: any, index: number) {
  const nftContract = getNFT1Contract(web3)
  return new Promise(async(resolve, reject) => {
    await nftContract.methods
      .witchInfoList(index.toString())
      .call((err: any, data: any) => {
        if (err) {
          reject(err)
        }
        resolve(data)
      })
  })
}

// export function callMint(web3: any, address: string, price: string, count: number, gaslimit: string) {
//   const nftContract = getNFT1Contract(web3)
//   const cost = convertStringToNumber(price) * count

//   return new Promise(async(resolve, reject) => {
//     await nftContract.methods
//       .mintGENISIS(count)
//       .send({ from: address, value: new BigNumber(cost) }, (err: any, data: any) => {
//         if (err) {
//           reject(err)
//         }
//         resolve(data)
//       })
//   })
// }

export const callMint = async(web3: any, address: string, price: string, count: number) => {
  const nftContract = getNFT1Contract(web3)
  const cost = convertStringToNumber(price) * count
  try {
    await nftContract.methods.mint(count).send({from: address, value: new BigNumber(cost)});
    return {
      success: true
    }
  } catch (error:any) {
    return {
      success: false,
      err: error.message
    }
  }
}

export const callStake = async(web3: any, collection: string, address: string, tokenIds: Number[]) => {
  const stakingContract = getStakingContract(web3)
  try {
    await stakingContract.methods.stake(collection, tokenIds).send({from: address});
    return {
      success: true
    }
  } catch (error:any) {
    return {
      success: false,
      err: error.message
    }
  }
}

export const callUnstake = async(web3: any, collection: string, address: string, tokenIds: Number[]) => {
  const stakingContract = getStakingContract(web3)
  try {
    await stakingContract.methods.unstake(collection, tokenIds).send({from: address})
    return {
      success: true
    }
  } catch (error:any) {
    return {
      success: false,
      err: error.message
    }
  }

  // return new Promise(async(resolve, reject) => {
  //   await stakingContract.methods
  //     .unstake(tokenIds)
  //     .send({ gasLimit: gaslimit, from: address }, (err: any, data: any) => {
  //       if (err) {
  //         reject(err)
  //       }
  //       resolve(data)
  //     })
  // })
}

export const callClaim = async(web3:any, address: string) => {
  const stakingContract = getStakingContract(web3)
  try {
    await stakingContract.methods.claim().send({from: address})
    return {
      success: true,
    }
  } catch (error:any) {
    console.log("Beast", error);
    return {
      success: false,
      err: error.message
    }
  }
}

export const callSetApprovalForAll = async(web3: any, collection: string, address: string) => {
  const stakingAddress = getStakingAddress()
  let nftContract: any;
  if (collection == getNFTAddress()) {
    nftContract = getNFT1Contract(web3)
  } else if (collection == getNFTAddress2()) {
    nftContract = getNFT2Contract(web3)
  }
  try {
    await nftContract.methods.setApprovalForAll(stakingAddress, true).send({from:address})
    return {
      success: true
    }
  } catch (error:any) {
    return {
      success:false,
      err: error.message
    }
}

  // new Promise(async(resolve, reject) => {
  //   await nftContract.methods
  //     .setApprovalForAll(stakingAddress, true)
  //     .send({ gasLimit: gaslimit, from: address }, (err: any, data: any) => {
  //       if (err) {
  //         reject(err)
  //       }
  //       resolve(data)
  //     })
  // })
}

export function callClaimReward(web3: any, address: string, gaslimit: string) {
  const stakingContract = getStakingContract(web3)

  return new Promise(async(resolve, reject) => {
    await stakingContract.methods
      .claim()
      .send({ gasLimit: gaslimit, from: address }, (err: any, data: any) => {
        if (err) {
          reject(err)
        }
        resolve(data)
      })
  })
}
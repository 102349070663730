import React, { useEffect, useState } from 'react'
import BigNumber from 'bignumber.js'
import styled from 'styled-components'
import { useUserData, useConnectedManager } from 'state/user/hooks'
import { useNFTData } from 'state/nft/hooks'
import { getFullDisplayBalance } from 'utils/formatBalance'
import { callMint } from 'utils/calls/nft'
import { useWeb3 } from 'utils/useWeb3'
import { MAX_MINT_COUNT, GAS_LIMIT } from 'constants/config'
import { ActionArea, ActionButton, Card, CardHeader, ControlButton, ControlContent, CrossLine, DetailArea, DetailedCard, DetailTitle, DetailValue, Divider, ImageArea } from './styles'
import Swal from 'sweetalert2'
import { toast } from 'react-toastify'

const MintInfo = styled.div`
  margin: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
`

const MintImage = styled.img`
  width: 150px;
  border-radius: 160px;
  border: 2px solid #d94e4e;
`
const Level = styled.div`
  font-size: 26px;
  font-weight: 700;
  margin: 20px 15px;
  margin-bottom: 0;
`
interface DetailProps {
  title: string
  value: string
}

const MintCard: React.FC = () => {
  const web3 = useWeb3()
  const [count, setCount] = useState(1)
  const [totalGasLimit, SetTotalGasLimit] = useState('300000')
  const [connected] = useConnectedManager()
  const [pending, setPending] = useState(false);
  const [stakedItems, setStakedItems] = useState<Number[]>([])
  const [nftBalance, setNFTBalance] = useState(0)

  const [userData] = useUserData()
  const [nftBasicData] = useNFTData()

  // console.log("Beast", nftBasicData)

  const { address, ethBalance } = userData
  const { mintPrice, totalSupply, data } = nftBasicData

  useEffect(() => {
    setStakedItems(userData.stakedItems);
    setNFTBalance(userData.nftBalance1);
  }, [userData])

  const maxCount = MAX_MINT_COUNT - data.length

  useEffect(() => {
    if (data.length === MAX_MINT_COUNT) {
      setCount(0)
    }
  }, [data])

  const handlePlus = () => {
    const mintCount = count + 1
    if (mintCount >= maxCount) {
      setCount(maxCount)
      SetTotalGasLimit(String(GAS_LIMIT * maxCount))
    } else {
      setCount(mintCount)
      SetTotalGasLimit(String(GAS_LIMIT * mintCount))
    }
  }

  const handleMinus = () => {
    const mintCount = count - 1
    if (mintCount < 1) {
      setCount(1)
    } else {
      setCount(mintCount)
    }
  }

  const handleMint = async() => {
    setPending(true);
    if (address && web3) {
      try {
          let res = await callMint(web3, address, mintPrice, count)
          if(res.success) {
            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: 'NFT Mint Success'
            })
          } else {
            toast.error("Transcation has been failed. " + res.err);
          }
      } catch (error) {
        toast.error("Transcation has been failed. " + error);
      }
    }
    setPending(false);
  }

  const Detail: React.FC<DetailProps> = ({ title, value }) => {
    return (
      <DetailedCard>
        <DetailTitle>{title}</DetailTitle>
        <DetailValue>{value}</DetailValue>
      </DetailedCard>
    )
  }

  // const zeroBalance = !new BigNumber(marsriseBalance).gt(0)

  return (
    <Card>
      <CardHeader>Mint VM SHIPS</CardHeader>
      <MintInfo>
        <DetailArea>
          <Detail title="VM SHIPS Supply" value={`10013 / ${totalSupply}`} />
          <Divider />
          <Detail title="Your VM SHIPS" value={connected ? `${stakedItems.length + Number(nftBalance)}` : '0'} />
        </DetailArea>
        <DetailArea>
          {/* <Detail title="VM SHIPS Price" value={`${getFullDisplayBalance(new BigNumber(mintPrice), 18, 0)} AVAX`} /> */}
          <Detail title="VM SHIPS Price" value={`${Number(mintPrice) / 10**18} AVAX`} />
          <Divider />
          <Detail title="Your AVAX Balance" value={connected ? `${getFullDisplayBalance(new BigNumber(ethBalance))} AVAX` : '0 AVAX'} />
        </DetailArea>
      </MintInfo>
      <ImageArea>
        <MintImage src="images/mint.gif" alt="mint" />
        <CrossLine />
      </ImageArea>
      <div>
        <ActionArea>
          <ControlButton onClick={handleMinus} disabled={count <= 1}>
            -
          </ControlButton>
          <ControlContent>{count}</ControlContent>
          <ControlButton onClick={handlePlus} disabled={count >= maxCount}>
            +
          </ControlButton>
        </ActionArea>
        <ActionArea>
        <ActionButton onClick={handleMint} disabled={!connected || count === 0 || maxCount === 0 || pending}>
          MINT
        </ActionButton>
        </ActionArea>
      </div>
      <MintInfo>
        {/*<DetailArea>
          <Detail title="Your CroWitches" value={connected ? `${data.length}` : '0'} />
        </DetailArea>
        <DetailArea>
          <Detail title="Your CRO Balance" value={connected ? `${getFullDisplayBalance(new BigNumber(AVAXBalance))} CRO` : '0 CRO'} />
        </DetailArea> */}
      </MintInfo>
    </Card>
  )
}

export default MintCard

import { useEffect } from 'react'
import { useAppDispatch } from 'state'
import { updateRewardAmount, updateMintPrice, updateTotalSupply, updateData, } from './actions'
import { callRewardAmount, callMintPrice, callTotalSupply} from 'utils/calls/nft'
import { useSlowFresh, useFastFresh } from 'hooks/useRefresh'
import { useWeb3 } from 'utils/useWeb3'
import { useEthBalance } from '../user/fetch'

export const useMintPrice = (web3: any, address: string) => {
  const dispatch = useAppDispatch()
  const fastRefresh = useFastFresh()
  useEffect(() => {
    const getNFTMintPrice = async() => {
      const result = await callMintPrice(web3, address);
      if (result.success) {
        dispatch(updateMintPrice({ mintPrice: `${result.result}` }))
      }
    }
    if (web3 && address) {
      getNFTMintPrice()
    }
  }, [web3, address, dispatch, fastRefresh])
}

export const useTotalSupply = (web3: any) => {
  const dispatch = useAppDispatch()
  const fastRefresh = useFastFresh()
  useEffect(() => {
    const getNFTTotalSupply = async() => {
      const result = await callTotalSupply(web3)
      if (result) {
        dispatch(updateTotalSupply({ totalSupply: Number(result) }))
      }
    }
    if (web3) {
      getNFTTotalSupply()
    }
  }, [web3, dispatch, fastRefresh])
}

// export const useRewardAmount = (web3: any, address: string) => {
//   const dispatch = useAppDispatch()
//   const fastRefresh = useFastFresh()
//   useEffect(() => {
//     const getRewardAmount = async() => {
//       const result = await callRewardAmount(web3, address)
//       if (result) {
//         dispatch(updateRewardAmount({rewardAmount: `${result}`}))
//       }
//     }
//     if (web3 && address) {
//       getRewardAmount()
//     }
//   })
// }

export const useFetchNFTData = (address: string) => {
  const web3 = useWeb3()
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(updateData({data: []}))
  }, [address, dispatch])

  useMintPrice(web3, address)
  useTotalSupply(web3)
  // useRewardAmount(web3, address)
  useEthBalance(web3, address)
}
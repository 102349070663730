export interface ContractSetup {
  address: string;
  abi: any[];
}

export const DAI_CONTRACT: { [chainId: number]: ContractSetup } = {
  1: {
    address: "0x6b175474e89094c44da98b954eedeac495271d0f",
    abi: [
      {
        constant: true,
        inputs: [{ name: "src", type: "address" }],
        name: "balanceOf",
        outputs: [{ name: "", type: "uint256" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: false,
        inputs: [
          { name: "dst", type: "address" },
          { name: "wad", type: "uint256" },
        ],
        name: "transfer",
        outputs: [{ name: "", type: "bool" }],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
    ],
  },
};

const contracts = {
  marsrise: {
    1: "0x6b175474e89094c44da98b954eedeac495271d0f",
    4: "0x30272ca83C2A8EeE5b461904Fb0391f3dCdAEABA",
    43113: "0xAe5d55541d2987483bBe5Bb5de47AfB06B97B304",
    43114: "0x184079Ca987F562ae6a0c59f4BE5cADB20323863",
    25: "0x5Eb71485f0736d368ddC5f290ac217d2A877fCf9",
    42161: "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
    5: "",
  },
  nft: {
    1: "0x6b175474e89094c44da98b954eedeac495271d0f",
    4: "0x418D7288086Fd4D3950b691B6e63b4F7b0e9330D",
    43113: "0xe317ef549d8086d2ed59124Cca38C99c9f201E47",
    43114: "0x411A93b58C7631351419ddfC6003A16fa5917aB7",
    25: "0x95603A09FE2D2aFDD7CE5d0E3cD83319666E62d9",
    42161: "0x17798ba794AD94daD0b7d3f4b9BC2e9F6486d4b9",
    5: "0xBd895A6BB9F3C875Bb27AA0106460C978A3A3F09"
  },
  staking: {
    1: "0x6b175474e89094c44da98b954eedeac495271d0f",
    4: "0x418D7288086Fd4D3950b691B6e63b4F7b0e9330D",
    43113: "0xb70D89d34E3567a1A6aD4ABD0fD70E2Dfb395594",
    43114: "0x1B29a0654829886F3121e513E2A935a4F9E30a20",
    25: "0x95603A09FE2D2aFDD7CE5d0E3cD83319666E62d9",
    42161: "0x17798ba794AD94daD0b7d3f4b9BC2e9F6486d4b9",
    5: "0xea3064b7aA485E999Cd6D5783578125525493B40"
  },
  token: {
    1: "0x6b175474e89094c44da98b954eedeac495271d0f",
    4: "0x418D7288086Fd4D3950b691B6e63b4F7b0e9330D",
    43113: "0x660639CB08736Dd7bB563497393B6E84F6ad1599",
    43114: "0xDF390B83FcCc4B697cac66B74753dCFdec4f445a",
    25: "0x95603A09FE2D2aFDD7CE5d0E3cD83319666E62d9",
    42161: "0x17798ba794AD94daD0b7d3f4b9BC2e9F6486d4b9",
    5: "0xFb2d6860F9E209122EE97D30B65066f4978AE599"
  },
  nftgen2: {
    1: "0x6b175474e89094c44da98b954eedeac495271d0f",
    4: "0x418D7288086Fd4D3950b691B6e63b4F7b0e9330D",
    43113: "0xAb21520fd304ff92c249b368EdE2D08b9d625E20",
    43114: "0xBbD9786f178e2AEBb4b4329c41A821921ca05339",
    25: "0x5ac04d6f18386ad2b556d16c2cB99F0d9f7564CB",
    42161: "0x17798ba794AD94daD0b7d3f4b9BC2e9F6486d4b9",
    5: ""
  },
  nftgen3: {
    1: "0x6b175474e89094c44da98b954eedeac495271d0f",
    4: "0x418D7288086Fd4D3950b691B6e63b4F7b0e9330D",
    43113: "0xd6bf8feedd8e3eb0499b25caba720dc5a7a09410",
    43114: "0xA27FD567a7dBC7ADFE712Cf1E3833F2b0E69BA5C",
    25: "0xfBf2727Bef4F6C4749948bed4aB865c448470898",
    42161: "0x17798ba794AD94daD0b7d3f4b9BC2e9F6486d4b9",
    5: ""
  },
  lottery: {
    1: "0x6b175474e89094c44da98b954eedeac495271d0f",
    4: "0x418D7288086Fd4D3950b691B6e63b4F7b0e9330D",
    43113: "0xd6bf8feedd8e3eb0499b25caba720dc5a7a09410",
    43114: "0xA27FD567a7dBC7ADFE712Cf1E3833F2b0E69BA5C",
    25: "0x13f4792DEBA0011b16566e8cbB6497382741675e",
    42161: "0x17798ba794AD94daD0b7d3f4b9BC2e9F6486d4b9",
    5: ""
  }
}

export default contracts
import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useFetchUserData } from 'state/user/fetch'
import { useAddressManager } from 'state/user/hooks'
import { useFetchNFTData } from 'state/nft/fetch'
import MintCard from './components/MintCard'
import StakingCard from './components/StakingCard'
import { CHAIN_ID } from 'constants/config'
import PStakingCard from './components/PStakingCard'

const StyledWrapper = styled.div`
  width: 100%;
  max-width: 1800px;
  border: 2px solid #d94e4e;
  border-radius: 8px;
  color: white;
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 40px;
`

const StyledTitle = styled.div`
  font-size: 30px;
  font-weight: 700;
`

const StyledSubTitle = styled.div`
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
`

const StyledCards = styled.div`
  display: flex;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  min-height: 530px;

  @media screen and (min-width: 310px){
    grid-template-columns: 1fr;
  }
  @media screen and (min-width: 852px){
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: 1200px){
    display: block;
  }
`

const StyledNotification = styled.div`
  margin-top: 20px;
  color: white;
  font-weight: 700;
  font-size: 26px;
  min-height: 40px;
`

interface HomeProps {
  address: string
  web3: any
  chainId?: number
}

const Home : React.FC<HomeProps> = ({ web3, address: connectedAddress, chainId }) => {
  const [address, setAddress] = useAddressManager()

  useEffect(() => {
    if (address !== connectedAddress) {
      setAddress(connectedAddress)
    }
  }, [address, connectedAddress, setAddress])

  useFetchNFTData(connectedAddress)
  useFetchUserData(connectedAddress)

  return (
    <>
      <StyledNotification>{CHAIN_ID !== chainId ? 'Make Sure you are on Avalanche' : ''}</StyledNotification>
      <StyledWrapper>
        <StyledTitle>VM SHIPS NFT</StyledTitle>
        <StyledSubTitle>Stake your VM SHIPS and Generate $VMC.</StyledSubTitle>
        <StyledCards>
          <MintCard />
          <StakingCard />
          <PStakingCard />
        </StyledCards>
      </StyledWrapper>
    </>
  )
}

export default Home
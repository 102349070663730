import { MaxUint256 } from '@ethersproject/constants'
import { getStakingContract, getTokenContract } from 'utils/contractHelper'

// export function callBalanceOf(contract: any, address: string, web3: any) {
//   return new Promise(async (resolve, reject) => {
//     await contract.methods.balanceOf(address).call({ from: '0x0000000000000000000000000000000000000000' }, (err: any, data: any) => {
//       if (err) {
//         reject(err)
//       }
//       resolve(data)
//     })
//   })
// }
export const callBalanceOf = async(web3: any, address: string) => {
  const tokenContract = getTokenContract(web3)
  try {
    let res = await tokenContract.methods.balanceOf(address).call({from: '0x0000000000000000000000000000000000000000'})
    return {
      success: true,
      result: res
    }
  } catch (error) {
    return {
      success: false,
      err: error
    }
  }
}
